import React, {  useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
// import logo from '../Assets/IconChat.png';
import './ScrollToTop.scss';

export default function ScrollToTop() {
  // const [scrollState, setScrollState] = useState(false);
  const location = useLocation();

  const toTop = () => {
    window.scrollTo({ top: 0 });
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     window.pageYOffset > 200 ? setScrollState(true) : setScrollState(false);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    toTop();
  }, [location]);


  return (
   null
  );
}
