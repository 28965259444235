import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageKey = 'selectedLanguage';

const storedLanguage = localStorage.getItem(languageKey);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./locales/en.json'),
      },
      ru: {
        translation: require('./locales/ru.json'),
      },
      hy: {
        translation: require('./locales/hy.json'),
      },
    },
    lng: storedLanguage || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

console.log('i18n initialized:', i18n);

// Listen for language changes and store the selected language
i18n.on('languageChanged', (lng) => {
  localStorage.setItem(languageKey, lng);

  // Update the URL with the language parameter
  const currentUrl = window.location.href;
  const newUrl = currentUrl.replace(/\/lang=[a-z]{2}/i, '') + `/lang=${lng}`;
  window.history.pushState({}, '', newUrl);
});

export default i18n;
