import React, {useEffect} from 'react';
import './Footer.scss';
import { Link } from "react-router-dom";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import {HiPhone} from 'react-icons/hi';
import {MdEmail} from 'react-icons/md';
import {FaLocationArrow} from 'react-icons/fa';
import logo from '../Assets/logoless1.png';


import Aos from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {

  const { t } = useTranslation();

  useEffect(()=>{
    Aos.init({duration:2000})
  },[])

  const contactInfo = {
    
    emails: ['info@lexbonus.am', 'office@lexbonus.am', 'travel@lexbonus.am'],
    
  };

  return (
    <div className='footer'>
      <div className="secContainer container">
        <div className="content grid">

          <div data-aos='fade-up' data-aos-duration='2000' className="row">
          <div className="loogoDiv">
            <img className='loogoless' src={logo} alt="logo" />
            <h3>LEX BONUS</h3>
        </div>
            <div className="spanText12">
            {t('contactss')}
            </div>
            <div className="contactDiv">
              <span className="flex ">
                <HiPhone/>
                <div className='footerflexphone'>
                <span>+374 11 33 13 03</span>
                <span>+374 44 33 13 03</span>
                </div>
                
              </span>
              <span className="flex">
                <FaLocationArrow/>
                <span>{t('sayatN')}</span>
              </span>
              <span className="flex">
                <MdEmail/>
              </span>
              <ul>
                  {contactInfo.emails.map((email, index) => (
                  <li key={index}>
                    <a href={`mailto:${email}`}>{email}</a>
                  </li>
                  ))}
                </ul>
              
            </div>
          </div>
          <div data-aos='fade-up' data-aos-duration='2000' className="row">
            <div className="spanText12">
              LEX BONUS COMPANY
            </div>
            <div className="singleNews">
              <span className="text">
              {t('companyp1')}
              </span>
              
            </div>
            <div className="singleNews">
              <span className="text">
              {t('copmanyp2')}
              </span>
              
            </div>
          </div>
          <div data-aos='fade-up' data-aos-duration='2000' className="row">
            <div className="spanText12">
            {t('quick')}
            </div>
            <div className="footerLinks">
              <ul>
              <li>
                    <Link to='/'>{t('about')}</Link>
                </li>
                <li >
                    <Link to='/services' >{t('services')}</Link>
                </li>
                <li>
                    <Link to='/pop-tours' >{t('tour')}</Link>
                </li>
                <li >
                    <Link to='/armenia' >{t('armenia')}</Link>
                </li>
                <li >
                    <Link to='/contact'>{t('contact')}</Link>
                </li>
                <li className="navItem">
                    <a    className="menuLink"><LanguageSwitcher/></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className='bottomDiv flex'>
          <p>Copyright 2023 JiTech - All rights reseved </p>
          <p></p>
        </div> */}
      </div>
    </div>
  )
}

export default Footer
