import React,{useState} from 'react';
import './NavBar.scss';
import { Link } from "react-router-dom";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import {AiFillCloseCircle} from 'react-icons/ai';
import {BsFacebook} from 'react-icons/bs';
import {AiFillInstagram} from 'react-icons/ai';
import {RiWhatsappFill} from 'react-icons/ri';
import {TbGridDots} from 'react-icons/tb';
import logo from '../Assets/logoless1.png';


const NavBar = () => {

    const { t } = useTranslation();

    const socialLinks = {
        facebook: 'https://www.facebook.com/profile.php?id=100086739680745',
        instagram: 'https://www.instagram.com/lex_bonus',
        whatsapp: 'https://wa.me/+37444331303', // Replace with your WhatsApp number
      };

    const [active, setActive] = useState('menuDiv');

    const showNavbar = ()=> {
        setActive('menuDiv activeNavbar')
    }

    const removeNavbar = ()=> {
        setActive('menuDiv')
    }


  return (
    <div>
      <div className="header flex">
        <div className="logoDiv">
           <Link to='/'><img className='logoless' src={logo} alt="logo" /></Link> 
            <h3>LEX BONUS</h3>
        </div>
        <div className={active}>
            <ul className="menuLists">
                {/* <li className="navItem">
                    <a href="#" onClick={removeNavbar} className="menuLink">{t('home')}</a>
                </li> */}
                <li className="navItem">
                    <Link to='/' onClick={removeNavbar} className="menuLink">{t('about')}</Link>
                </li>
                <li className="navItem">
                    <Link to='/services' onClick={removeNavbar} className="menuLink">{t('services')}</Link>
                </li>
                <li className="navItem">
                    <Link to='/pop-tours' onClick={removeNavbar} className="menuLink">{t('tour')}</Link>
                </li>
                <li className="navItem">
                    <Link to='/armenia' onClick={removeNavbar} className="menuLink">{t('armenia')}</Link>
                </li>
                <li className="navItem">
                    <Link to='/contact' onClick={removeNavbar} className="menuLink">{t('contact')}</Link>
                </li>
                <li className="navItem">
                    <a    className="menuLink"><LanguageSwitcher/></a>
                </li>
            </ul>
            <div onClick={removeNavbar} className="closeNavbar">
                <AiFillCloseCircle className='icon'/>
            </div>
        </div>
        <div className='socialIcons flex'>
            <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" className='icon'>
                  <BsFacebook />
                  
                </a>
           <a href={socialLinks.whatsapp} target="_blank" rel="noopener noreferrer" className='icon'>
                  <RiWhatsappFill />
                  
                </a>
            <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer"  className='icon'>
                  <AiFillInstagram />
                  
                </a>
                
        </div>
        <div onClick={showNavbar} className="toggleNavbar" >
            <TbGridDots className='icon'/>
        </div>
      </div>  
    </div>
  )
}

export default NavBar
