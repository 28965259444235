import React, { lazy, Suspense } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import MainLayout from './MainLayout';
import Spinner from './Spinner';

// Lazy-loaded components
const Home = lazy(() => import('./Components/Home/Home'));
const Review = lazy(() => import('./Components/Review/Review'));
const Tour = lazy(() => import('./Components/Tours/Tour'));
const Discount = lazy(() => import('./Components/Discount/Discount'));
const Cards = lazy(() => import('./Components/Cards/Cards'));
const Staff = lazy(() => import('./Components/Staff/Staff'));
const AboutUs = lazy(() => import('./Components/AboutUs/AboutUs'));
const ResidencePermitComponent = lazy(() => import('./Components/ResidencePermitComponent/ResidencePermitComponent'));
const ToursArmenia = lazy(() => import('./Components/TourArmenia/ToursArmenia'));
const EuropTour = lazy(() => import('./Components/PopTours/EuropTour/EuropTour'));
const VizitEurope = lazy(() => import('./Components/PopTours/VizitCoutry/VizitEurope'));
const VizitAfrica = lazy(() => import('./Components/PopTours/VizitAfrica/VizitAfrica'));
const VizitAsia = lazy(() => import('./Components/PopTours/VizitAsia/VizitAsia'));
const VizitCyprus = lazy(() => import('./Components/PopTours/VizitCyprus/VizitCyprus'));
const AfricaTour = lazy(() => import('./Components/PopTours/AfricaTour/AfricaTour'));
const AsiaTour = lazy(() => import('./Components/PopTours/AsiaTour/AsiaTour'));
const CyrusTour = lazy(() => import('./Components/PopTours/CyprusTour/CyprusTour'));

const App = () => {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <Home />
                  <Review />
                  <Tour />
                  <Discount />
                  <Cards />
                  <Staff />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="contact"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <AboutUs />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="services"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <ResidencePermitComponent />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="armenia"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <ToursArmenia />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="pop-tours"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <Tour />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="europe-tours"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <EuropTour />
                  <VizitEurope />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="africa-tours"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <AfricaTour />
                  <VizitAfrica />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="asia-tours"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <AsiaTour />
                  <VizitAsia />
                </MainLayout>
              </Suspense>
            }
          />
          <Route
            path="cyprus-tours"
            element={
              <Suspense fallback={<Spinner />}>
                <MainLayout>
                  <CyrusTour />
                  <VizitCyprus />
                </MainLayout>
              </Suspense>
            }
          />
        </Routes>
      </HashRouter>
    </div>
  );
};

export default App;
