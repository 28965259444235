import React from 'react';
import { useTranslation } from 'react-i18next';

import './LanguageSwitcher.scss';


const LanguageSwitcher = () => {
  const {  i18n } = useTranslation();
  

  const changeLanguage = (language) => {
    setTimeout(() => {
      i18n.changeLanguage(language);
    }, 600);
  };

  return (
    <div className="language-switcher">
    <select
      value={i18n.language}
      onChange={(e) => changeLanguage(e.target.value)}
    >
      <option value="en"> English</option>
      <option value="ru"> Русский</option>
      <option value="hy"> Հայերեն</option>
    </select>
  </div>
  );
};

export default LanguageSwitcher;
