// Spinner.js
import React from 'react';
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';

const override = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Use 100% of the viewport height */
`;

const Spinner = () => {
  return (
    <div css={override}>
      <DotLoader color={'#36D7B7'} size={150} />
    </div>
  );
};

export default Spinner;
